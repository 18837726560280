.chat-input {
    display: flex;
}

textarea {
    flex-grow: 1;
    resize: none;
    border: none;
    padding: 10px;
    border-radius: 8px;
    font-family: poppins;
    font-size: "2rem";
}

textarea:focus {
    outline: none;
}