.page-body
{
	height: 100vh;
	display: flex;
	overflow: hidden;
	background-color: peachpuff;
}

.side-bar
{
	height: 100vh;
	width: 250px;
	position: fixed;
/*	top: 0;*/
	left: 0;
	/* background-color: darkblue; */
}

.page-content
{
	height: 100vh;
	margin-left: 250px;
	/* background: rgb(212,213,232);
	background: linear-gradient(16deg, rgba(212,213,232,1) 0%, rgba(232,232,232,1) 100%); */
	padding: 30px;
}

.page-content-2
{
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-left: 250px;
	width: calc(100vw - 250px);
	max-width: calc(100vw - 250px);
	overflow: hidden;
	/* background: rgb(212,213,232);
	background: linear-gradient(16deg, rgba(212,213,232,1) 0%, rgba(232,232,232,1) 100%); */
	padding: 20px;
	padding-bottom: 10px;
}

.page-content-11
{
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-left: 250px;
	width: calc(100vw - 250px);
	max-width: calc(100vw - 250px);
	/* overflow: scroll; */
	/* background: rgb(212,213,232);
	background: linear-gradient(16deg, rgba(212,213,232,1) 0%, rgba(232,232,232,1) 100%); */
	padding: 20px;
	padding-bottom: 10px;
}

.page-content-12 {
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-left: 250px;
	width: calc(100vw - 250px);
	max-width: calc(100vw - 250px);
	padding: 20px;
	padding-bottom: 40px; /* Extra padding for last card visibility */
	box-sizing: border-box;
  }



.page-content-without-padding
{
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-left: 250px;
	width: calc(100vw - 250px);
	max-width: calc(100vw - 250px);
	overflow: hidden;
	/* background: rgb(212,213,232);
	background: linear-gradient(16deg, rgba(212,213,232,1) 0%, rgba(232,232,232,1) 100%); */
	padding: 5px;
	padding-bottom: 5px;
}

.page-content-2-5
{
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-left: 250px;
	width: calc(100vw - 250px);
	max-width: calc(100vw - 250px);
	/* background: rgb(212,213,232);
	background: linear-gradient(16deg, rgba(212,213,232,1) 0%, rgba(232,232,232,1) 100%); */
	padding: 20px;
	padding-bottom: 10px;
}

.page-content-3
{
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-left: 50px;
}

.page-content-7
{
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-left: 250px;
	width: calc(100vw - 250px);
	max-width: calc(100vw - 250px);
	/* overflow: hidden; */
	/* background: rgb(212,213,232);
	background: linear-gradient(16deg, rgba(212,213,232,1) 0%, rgba(232,232,232,1) 100%); */
	padding: 20px;
	padding-bottom: 10px;
	height: 88%;
}

.page-content-8
{
	display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(12, 1fr);
	margin-left: 60px;
	width: calc(100vw - 100px);
	max-width: calc(100vw - 100px);
	overflow: hidden;
	

	/* background: rgb(212,213,232);
	background: linear-gradient(16deg, rgba(212,213,232,1) 0%, rgba(232,232,232,1) 100%); */
	/* padding: 20px; */
	/* padding-bottom: 10px; */
}

.round-table-employee-card-item div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left !important;
}