.top-bar
{
	margin: 0px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: space-between;
/*	background: #202123;*/
/*	border-bottom: 1px solid grey;*/
	min-width: 600px;
	width: 100%;
}

.top-bar-left-group
{
	display: flex;
	flex-direction: row;
	justify-content: spacebetween;
	align-items: center;
	margin-left: 0;
	padding: 2px;
}

.top-bar-right-group
{
	display: flex;
	flex-direction: row;
/*	justify-content: spacebetween;*/
	gap: 3px;
	align-items: center;
	margin-right: 0;
	padding: 2px;
}

.top-bar-left-group img
{
	display: inline-block;
	height: 30px;
/*	max-height: 100%;*/
	padding-left: 10px;
	padding-right: 10px;
}

.top-bar-left-group > h2, h5
{
	display: inline-block;
	color: white;
	margin-top: 10px;
	margin-bottom: 10px;
}

.top-bar-time
{
	padding: 2px;
	display: inline-block;
	color: white;
	padding-left: 10px;
	padding-right: 10px;
}

.dark-mode-button
{
	padding: 2px;
	display: inline-block;
	padding-left: 10px;
	padding-right: 10px;
}