#loginBox
{
	height: 99vh;
}

div#loginBox     
{
	--iframe-height: 100px;
    overflow: hidden;
    height: var(--iframe-height);
}  

.login-card-div
{
	--width: 380px;
	--height: 450px;
	width: var(--width);
	height: var(--height);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto;
	margin-top: calc((100vh - var(--height))/2);
	border-radius: 15px;
	box-shadow: 1px 1px 10px 1px grey; 
}

.login-loading
{
	animation: pulse 3s infinite;
}

@keyframes pulse {
	0% {
		box-shadow: 1px 1px 10px 1px grey; 
	}

	70% {
		box-shadow: 1px 1px 30px 5px grey; 
	}

	100% {
		box-shadow: 1px 1px 10px 1px grey; 
	}
}