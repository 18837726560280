.App 
{
  text-align: center;
}

.page-container 
{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap');

/* @font-face 
{
  font-family: "Zoho_Puvi_Extralight";
  src:url("./fonts/Zoho Puvi Extralight.otf");
}

@font-face 
{
  font-family: "Zoho_Puvi_Light";
  src:url("./fonts/Zoho Puvi Light.otf");
}

@font-face 
{
  font-family: "Zoho_Puvi_Medium";
  src:url("./fonts/Zoho Puvi Medium.otf");
}

@font-face 
{
  font-family: "Zoho_Puvi_Semibold";
  src:url("./fonts/Zoho Puvi Semibold.otf");
}

@font-face 
{
  font-family: "Zoho_Puvi_Black";
  src:url("./fonts/Zoho Puvi Black.otf");
}

@font-face 
{
  font-family: "Zoho_Puvi_Extrablack";
  src:url("./fonts/Zoho Puvi Extrablack.otf");
} */

body
{
  font-family: 'Poppins', sans-serif;
}