.sticky {
    position: sticky;
    top: 0;
    z-index: 100;
}

.react-calendar-timeline {
    font-family: poppins;
    color: inherit !important;
}

.rct-scroll {
    color: inherit !important;
}

.rct-items {
    color: inherit !important;
}

.rct-item {
    /* color: inherit !important; */
    /* text-overflow: ellipsis !important; */
    /* overflow: hidden !important; */
}

.rct-item-content {
    text-shadow: 1px 1px 10px rgba(255, 255, 255, 0.293) !important;
}

.rct-header-root {
    background: rgb(38,42,50) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white !important;
    border-bottom: none !important;
    box-shadow: 0px 10px 20px -10px #232a3b8c !important;
}

.rct-dateHeader {
    color: black !important;
    border: none !important;
    border-left: 1px solid #ced8e3 !important;
    font-size: 1rem !important;
    padding: 10px !important; 
}

.rct-calendar-header {
    border: none !important;
    border-bottom: 1px solid #202832 !important;
    /* box-shadow: 0px 8px 20px -10px #888 !important; */
    z-index: 2;
}

.rct-dateHeader.rct-dateHeader-primary {
    /* color: white !important; */
    color: inherit !important;
    border-left: 1px solid #535455 !important;
}

.rct-sidebar {
    color: inherit !important;
    border-right: none !important;
    /* border-left: 1px solid #d4d8de !important; */
    border-left: 1px solid #01204f36 !important;
    box-shadow: 7px 0 20px -10px #3847678c !important;
    z-index: 2;
}

.rct-sidebar-row {
    color: inherit !important;
    /* border-bottom: 1px solid rgb(197, 211, 193) !important; */
    border-bottom: 1px solid rgba(98, 113, 159, 0.483) !important;
    padding-left: 10px !important;
}

.rct-sidebar-row-even {
    color: inherit !important;
    /* background-color: #edf2ef !important; */
    background-color: #2b3b4e0e !important;
    /* color: black !important; */
}

.rct-sidebar-row-odd {
    color: inherit !important;
    background-color: #34383c13 !important;
    /* color: black !important; */
}

.rct-hl-even {
    /* background-color: #e8ebee !important; */
    background-color: rgba(255, 255, 255, 0.06) !important;
    border-bottom: none !important;
}

.rct-hl-odd {
    /* background-color: #e8ebee !important; */
    background-color: rgba(255, 255, 255, 0.05) !important;
    border-bottom: none !important;
}

.rct-vl {
    border-left: 1px solid rgba(51, 78, 133, 0.156) !important;
}

.rct-item {
    border-radius: 3px !important;
    font-size: 0.8rem !important;
    font-weight: bold !important;
    /* background-color: rgb(40, 112, 84, 0.8) !important; */
    border: none !important;
}

.rct-vl.rct-day-6 {
    background-color: rgba(220, 227, 245, 0.01) !important;
}

.rct-vl.rct-day-0 {
    background-color: rgba(220, 227, 245, 0.01) !important;
}