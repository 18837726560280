.notification-row {
  justify-content: space-between;
  line-break: strict;
}

.notification-value {
  font-size: 10.5px;
  font-weight:normal;
  text-align: center;
  color: rgb(67,72,64);
  padding: 3px 10px;
}

.notification-avatar{
  padding: 0px 6px 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vertical-tab-withoutButton{
  position: absolute;
  right: 3px;
  top: 48%;
  transform: translateY(-50%) rotate(180deg);
  writing-mode: vertical-rl;
  text-align: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  border-radius: 5px;
  color: rgb(70,136,71);
  background-color: #DFF0D8; /* Default color */
  font-size: 10px;
  font-weight: bold;
  width: 12px;
  height: 100%;
}

.vertical-tab {
  position: absolute;
  right: 3px;
  top: 48%;
  transform: translateY(-50%) rotate(180deg);
  writing-mode: vertical-rl;
  text-align: center;
  border-radius: 10px;
  color: rgb(70,136,71);
  background-color: #DFF0D8; /* Default color */
  font-size: 10px;
  font-weight: bold;
  width: 12px;
  height: 100%;
}
