@keyframes animate-in-and-out {
	entry 0% {
		opacity: 0;
		transform: translateY(100%);
	}

	entry 100% {
		opacity: 1;
		transform: translateY(0);
	}

	exit 0% {
		opacity: 1;
		transform: translateY(0);
	}

	exit 100% {
		opacity: 0;
		transform: translateY(-100%);
	}
}

.MuiTableContainer-root.external-table-container {
	max-height: calc(100vh - 150px);
}

.MuiTableRow-head.external-table-head>.MuiTableCell-root {
	background-color: #263238;
	color: white;
	font-weight: bold;
}
.MuiTableCell-root.external-table-grouper-header {
	padding: 6px;
	width: 10px;
}

.external-top-bar {
	display: grid;
	grid-template-columns: auto auto;
	margin-bottom: 10px;
}

.external-top-bar-left {
	text-align: left;
}

.MuiTableRow-root.info-div-row {
	background-color: #eceff1;
}

.rotated-text {
  position: relative;
  min-width: 3px  !important;
  writing-mode: vertical-rl !important;
  transform: rotate(180deg) !important;
  transform-origin: center;
  padding: 2px !important;
  color: white;
  line-height: 1 !important;
}
