.tickets_table_section {
    margin-top: 10px;
    display: flex;
    flex-grow: 1;
    border-radius: 10px;
    overflow: hidden;
}

.ticket_table_surface {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.ticket_table_container {
    height: 100%;
    max-height: 100% !important;
}

.flex_column {
    display: flex;
    flex-direction: column;
}

.flex_row_center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.flex_row_space_between {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-left: 30px !important;
    padding-right: 10px !important;
}

.flex_row_space_between_ {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-left: 30px !important;
    padding-right: 10px !important;
}

.flex_column_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex_column_left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    justify-content: space-between;
}

.flex_left_align {
    display: flex;
    align-items: flex-start !important;
    justify-content: space-between !important;
}

.flex_center_align {
    display: flex;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100% !important;
}

.bold {
    font-weight: bold;
}

.gap_small {
    gap: 9px !important;
}

.dri_button {
    border-radius: 100px !important;
    padding-right: 10px !important;
    padding-left: 2px !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    margin: 30px 40px !important;
    text-transform: none !important;
    min-width: 220px !important;
}

.component_button {
    border-radius: 100px !important;
    padding: 10px !important;
    padding-left: 15px !important;
    margin: 30px 40px !important;
    text-transform: none !important;
    min-width: 220px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.forward_type_button {
    border-radius: 100px !important;
    padding: 10px !important;
    padding-left: 15px !important;
    margin: 30px 40px !important;
    text-transform: none !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.bot_reply_text_box {
    margin: 30px 40px !important;
    text-transform: none !important;
    min-width: 480px !important;
    min-height: 400px !important;
    display: flex !important;
    padding: 10px;
    display: block;
    border-radius: 8px;
    border: 1px solid #ccc;
    resize: vertical;
    font-size: 1.2rem;
    line-height: 1.5;
    color: inherit !important;
    background-color: transparent !important;
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1);
    transition: border-color 0.2s, box-shadow 0.2s;
    text-decoration: none;
}

.forward_text_box {
    margin: 30px 30px 30px 20px !important;
    text-transform: none !important;
    min-width: 280px !important;
    min-height: 200px !important;
    max-height: 350px !important;
    display: flex !important;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    resize: vertical;
    font-size: 13px;
    line-height: 1.5;
    color: inherit !important;
    background-color: transparent !important;
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1);
    transition: border-color 0.2s, box-shadow 0.2s;
    text-decoration: none;
}

.bot_reply_text_box:focus {
    border-color: #0f766f;
    outline: none;
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1), 0 0 5px rgba(102, 205, 233, 0.6);
}

.forward_text_box:focus {
    border-color: #0f766f;
    outline: none;
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1), 0 0 5px rgba(102, 205, 233, 0.6);
}

.action_button {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 20px !important;
}

.close_ticket_note_container {
    padding: 15px 10px 15px 18px;
    margin: 10px;
    display: flex !important;
    flex-direction: row !important;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(0, 179, 255, 0.183);
    border-radius: 10px !important;
    border: 1px solid rgb(0, 195, 255);
    flex-grow: 1;
}

.close_ticket_iframe_container {
    padding: 10px;
    display: flex !important;
    flex-direction: column !important;
    height: 680px !important;
    position: relative !important;
    box-sizing: border-box !important;
    overflow: hidden !important;
}

.close_ticket_iframe {
    border: none;
    flex-grow: 1 !important;
}

.declare_as_incident_note_container {
    padding: 15px 10px 15px 18px;
    margin: 10px;
    display: flex !important;
    flex-direction: row !important;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(0, 179, 255, 0.183);
    border-radius: 10px !important;
    border: 1px solid rgb(0, 195, 255);
    flex-grow: 1;
}

.declare_as_incident_iframe_container {
    padding: 10px;
    display: flex !important;
    flex-direction: column !important;
    height: 680px !important;
    position: relative !important;
    box-sizing: border-box !important;
    overflow: hidden !important;
}

.delcare_as_incident_iframe {
    border: none;
    flex-grow: 1 !important;
}

.forward_list_item {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
}

.forward_list_item_left {
    min-width: 100px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.9rem;
    padding-left: 30px;
    padding-right: 20px;
}

.forward_list_item_right {
    min-width: 400px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.forward_list_item_right > :global(button) {
    min-width: none;
    margin: 20px !important;
}

/* header-styles */
.ticket_table_head:global(.dark) :global(th) {
    background-color: rgb(39,50,57) !important;
    color: white;
}

.ticket_table_head:global(.light) :global(th) {
    background-color: rgb(37,50,55) !important;
    color: white;
}

.side_grouper_head {
    width: 18px !important;
    max-width: 18px !important;
    padding: 0px !important;
    box-sizing: border-box !important;
    font-size: 0.7rem !important;
    font-weight: 900 !important;
    color: rgb(241, 239, 239) !important;
}

.side_grouper_main {
    width: 18px !important;
    max-width: 18px !important;
    padding: 0px !important;
    box-sizing: border-box !important;
    font-size: 0.7rem !important;
    font-weight: 900 !important;
    color: rgb(241, 239, 239) !important;
    border-radius: 3px !important;
}

.side_grouper_main_left {
    width: 18px !important;
    max-width: 18px !important;
    max-height: 120px !important;
    padding: 3px 0px !important;
    writing-mode: vertical-lr !important;
    rotate: 180deg !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    box-sizing: border-box !important;
    display: inline-block !important;
    text-transform: uppercase !important;
    user-select: none !important;
    /* text-shadow: 2px 2px 6px rgba(26, 1, 1, 0.807) !important; */
    white-space: nowrap !important;
    line-height: 18px !important;
}

.side_grouper_main_right {
    width: 18px !important;
    max-width: 18px !important;
    max-height: 120px !important;
    padding: 3px 0px !important;
    writing-mode: vertical-lr !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    box-sizing: border-box !important;
    display: inline-block !important;
    text-transform: uppercase !important;
    user-select: none !important;
    /* text-shadow: 2px 2px 6px rgba(26, 1, 1, 0.792) !important; */
    white-space: nowrap !important;
    line-height: 18px !important;
}

.more_section {
    width: 25px !important;
    padding: 0px !important;
}

.serial_number {
    width: 18px !important;
    padding: 2px !important;
}

/* header-styles-end */

/* row-styles */
.ticket_table_row:global(.dark) {
    background-color: rgb(34, 38, 44);
}

.ticket_table_row:global(.light) {
    background-color: rgb(243, 246, 245);
}

.time_fixed_width {
    min-width: 75px !important;
}

.description_max_width {
    max-width: 270px !important;
    word-wrap: break-word !important;
    /* overflow-y: hidden !important; */
    /* text-overflow: ellipsis !important; */
}

.last_loading_row > :global(td) {
    border-bottom: none !important;
}

.mouse_pointer {
    cursor: pointer;
}

.claim_button {
    border-radius: 100px !important;
}

.light_chip {
    font-weight: 600 !important;
    font-size: 0.7rem !important;
    text-transform: uppercase !important;
    border-radius: 10px;
    padding: 3px 5px;
    text-wrap: nowrap !important;
}

.light_chip.light {
    border: 0.1px solid #d13819 !important;
    background-color: rgba(255, 68, 0, 0.102);
}

.light_chip.dark {
    border: 0.1px solid #613a32 !important;
    background-color: rgba(255, 68, 0, 0.04);
}
/* row-styles-end */

/* snackbar-styles */
.feedback_snackbar.dark > :global(div) {
    background-color: rgb(171, 198, 216) !important;
}

.feedback_snackbar.light > :global(div) {
    background-color: rgb(53, 68, 95) !important;
}

.feedback_snackbar > :global(div) {
    min-width: 250px;
}

.feedback_snackbar {
    left: 50px !important;
    bottom: 10px !important;
}
/* snackbar-styles-end */

.ticket_content_full {
    flex-grow: 1 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.translate_y {
    transform: translateY(-40px) !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
    align-items: center !important;
    justify-content: center !important;

}


/* Unvisited link */
.desk_link :global(a):link {
    color: #007890 !important;
    text-decoration: none !important;
}

/* Visited link */
.desk_link :global(a):visited {
    color:  #018fab !important;
}

/* Mouse over link */
.desk_link :global(a):hover {
    color: #015f72 !important;
}

/* Selected link */
.desk_link :global(a):active {
    color: #015f72 !important;
}

.more_menu_main {
    padding: 0px !important;
}

.more_menu_item {
    padding: 12px !important;
    min-width: 230px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.temp_deleted_row {
    opacity: 0.3 !important;
}

.more_view_middle > :global(div):not(:global(.non-more-item)) {
    min-width: 600px !important;
    width: 600px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important; 
    box-sizing: border-box !important;
}
.more_view_top {
    height: 70px !important;
    width: 100% !important;
    font-size: 3rem !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content:space-between !important;
    padding-right: 10px !important;
    padding-left: 20px !important;
    box-sizing: border-box !important;

}
.more_view_middle {
    width: 100% !important;
    flex-grow: 1 !important;
    padding: 20px !important;
    box-sizing: border-box !important;
    overflow-y: auto !important;
    /* display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important; */
}
.more_view_bottom {
    height: 70px !important;
    width: 100% !important;
}

.more_view_item {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    min-height: 68px !important;
    box-sizing: border-box !important;
    width: 100% !important;
}

.more_view_item_left {
    min-width: 30% !important;
    max-width: 30% !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: flex-start;
    font-size: 0.9rem;
    padding-left: 30px;
    padding-right: 20px;
    box-sizing: border-box !important;
}

.more_view_item_right {
    min-width: 70% !important;
    max-width: 70% !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    box-sizing: border-box !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    word-wrap: break-word !important;
    max-height: 500px !important;
    overflow-y: auto !important;
}

/* .ticket_table_container :global(.MuiTableCell-root) {
    padding: 10px 1px !important;
} */

.dri_cell {
    width: auto !important;
    max-width: auto !important;
}

.more_view_status_display:global(.light) {
    font-size: 0.9rem !important;
    border: 1px solid black !important;
    background-color: rgba(50, 51, 52, 0.178) !important;
    padding: 5px !important;
    border-radius: 100px !important;
}

.more_view_status_display:global(.dark) {
    font-size: 0.9rem !important;
    border: 0.5px solid rgb(255, 255, 255) !important;
    background-color: rgba(255, 255, 255, 0.152) !important;
    color: rgb(255, 255, 255)!important;
    padding: 5px !important;
    border-radius: 100px !important;
}

.more_view_top {
    display: flex;
    align-items: center;
}


.ticket_id {
    font-size: 0.9rem !important;
    border: 2px solid red !important;
    color: rgb(220, 11, 105);
    background-color: rgba(241, 9, 110, 0.178) !important;
    padding: 7px !important;
    border-radius: 100px !important;
}

.deskTicketCell {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #000;
    padding: 3px;
    /* Other styles as needed */
  }
  
  .deskTicketLink {
    /* Any specific styles for the link inside the cell */
    text-decoration: none; /* Example */
    color: blue; /* Example */
  }