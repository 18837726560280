.mytasks-top-bar
{
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: auto auto;
	overflow: hidden;
}

.MuiOutlinedInput-root
{
	height: 35px;
	margin-right: 10px;
}

.MuiCollapse-wrapper
{
	display: flex;
}

.mytasks-top-bar-left-group
{
	text-align: left;
}

.mytasks-top-bar-right-group
{
	text-align: right;
}


.MuiTableContainer-root.mytasks-table-container
{
	max-height: calc(100vh - 150px);
}

.MuiTableRow-head.mytasks-table-head > .MuiTableCell-root
{
	background-color: #263238;
	color: white;
	font-weight: bold;
}

.MuiTableCell-root > .MuiBox-root.flex-column > p
{
	margin: 0px;
	display: block;
	margin-top: 5px;
}

.MuiTableCell-root > .MuiBox-root.flex-row > p
{
	margin: 0px;
	display: block;
	margin-left: 5px;
}

.MuiTableCell-root > .MuiBox-root.flex-column
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.MuiTableCell-root > .MuiBox-root.flex-row
{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}


/* ############################################### grouper styles ################################################# */

/* text */
.mytasks-table-grouper-component.mytasks-multi-row > div, .mytasks-table-grouper-component.mytasks-single-row > div
{
	position: relative;
	text-align: center;
	width: 6px;
	transform-origin: top left;
	transform: translate(-75%, 250%) rotate(-90deg);
	padding: 2px;
}

.mytasks-table-grouper-type.mytasks-multi-row > div, .mytasks-table-grouper-type.mytasks-single-row > div
{
	position: relative;
	text-align: center;
	width: 6px;
	transform-origin: top left;
	transform: translate(180%, -80%) rotate(90deg);
	padding: 2px;
}

.mytasks-table-grouper-type.mytasks-multi-row
{
	overflow: hidden;
}

.mytasks-table-grouper-component.mytasks-multi-row
{
	overflow: hidden;
}

/* grouper body */
.mytasks-table-grouper-component.MuiTableCell-root
{
	font-weight: 800;
	font-size: 15px;
	text-align: center;
	width: 6px;
	padding: 5px;
	background-color: rgb(74, 90, 102);
	border-radius: 3px;
	color: white;
	overflow: hidden;
	text-overflow: ellipsis;
}

.mytasks-table-grouper-type.MuiTableCell-root
{
	font-weight: 800;
	font-size: 15px;
	text-align: center;
	width: 6px;
	padding: 5px;
	background-color: rgb(74, 90, 102);
	border-radius: 3px;
	color: white;
	overflow: hidden;
	text-overflow: ellipsis;
}

.mytasks-single-row 
{
	height: 100px;
}

/* grouper top */
.MuiTableCell-root.mytasks-table-grouper-header
{
	padding: 6px;
	width: 6px;
}

.mytasks-table-grouper-component.color-a
{
	background-color: #009688 !important;
}

.mytasks-table-grouper-component.color-b
{
	background-color: #9c27b0 !important;
}

.mytasks-table-grouper-component.color-c
{
	background-color: #ff5722 !important;
}

.mytasks-table-grouper-component.color-d
{
	background-color: #2196f3 !important;
}

.mytasks-table-grouper-component.color-e
{
	background-color: #673ab7 !important;
}

.mytasks-table-grouper-type.Release
{
	background-color: #388e3c !important;
}

.mytasks-table-grouper-type.Research
{
	background-color: #880e4f !important;
}

 #list-view .slide-animation {
	animation: linear animate-in-and-out;
	animation-timeline: view();
} 


/* ################################################################################################################ */

.circular-image {
	width: 32px;
	height: 32px;
	border-radius: 50%;
  }
  .flex-container {
	display: flex;
	/* align-items: center; */
	padding-left: 0px;
  }
  
  .employee-name {
	/* padding: 10px;
    padding-bottom: 13px; */
	margin-left: 2px; /* Adjust the margin as needed */
  }




  /* ------------------------styles for employee tree dropdown-------------------------*/

  .filter-button {
    position: relative;
  }
  
  .filter-button__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    background-color: #fff;
    border: 1px solid #ccc;
    /* display: none; */
    z-index: 10;
  }
  
  .filtered-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    background-color: #fff;
    border: 1px solid #ccc;
    display: none;
    z-index: 10;
  }
  
  .filtered-dropdown__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
  }
  
  .filtered-dropdown__content {
    padding: 10px;
  }
  
  .filtered-dropdown__content select {
    width: 100%;
    height: 30px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  
  /*styles for DRI treee*/
  .dropdown-item.no-subitems {
    display: flex;
    align-items: center;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
  }
  
  /* .dropdown-item .arrow-icon {
    margin-right: 8px; 
  }
   */
  .no-subitems .arrow-icon {
    visibility: hidden;
  }
  
  .no-subitems .MuiAvatar-root {
    margin-left: 32px; 
  }
  