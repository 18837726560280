
.mytasks-top-bar
{
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: auto auto;
	overflow: hidden;
}

.MuiOutlinedInput-root
{
	height: 35px;
	margin-right: 10px;
}

.MuiCollapse-wrapper
{
	display: flex;
}

.mytasks-top-bar-left-group
{
	text-align: left;
	grid-column-start: 1;
	grid-column-end: 95;
}

.mytasks-top-bar-right-group
{
	text-align: right;
	grid-column-start: 95;
	grid-column-end: 100;
}

.filter-sort-overflow-container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.filter-sort-overflow-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.filter-sort-overflow-container {
	/* box-shadow: inset -30px 0 10px -10px ghostwhite; */
	-webkit-mask-image:  linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 0.3%, rgba(0,0,0,1) 85%, rgba(0,0,0,0) 100%);
}

.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.MuiTableContainer-root.mytasks-table-container {
	max-height: calc(100vh - 150px);
}

.MuiTableRow-head.mytasks-table-head > .MuiTableCell-root
{
	background-color: #263238;
	color: white;
	font-weight: bold;
}

.MuiTableCell-root > .MuiBox-root.flex-column > p
{
	margin: 0px;
	display: block;
	margin-top: 5px;
}

.MuiTableCell-root > .MuiBox-root.flex-row > p
{
	margin: 0px;
	display: block;
	margin-left: 5px;
}

.MuiTableCell-root > .MuiBox-root.flex-column
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.MuiTableCell-root > .MuiBox-root.flex-row
{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}


/* ############################################### grouper styles ################################################# */

/* text */
.mytasks-table-grouper-component.mytasks-multi-row > div, .mytasks-table-grouper-component.mytasks-single-row > div
{
	position: relative;
	text-align: center;
	width: 6px;
	transform-origin: top left;
	transform: translate(-75%, 250%) rotate(-90deg);
	padding: 2px;
}

.mytasks-table-grouper-component.loading {
	background-color: transparent !important;
}

.mytasks-table-grouper-type.mytasks-multi-row > div, .mytasks-table-grouper-type.mytasks-single-row > div
{
	position: relative;
	text-align: center;
	width: 6px;
	transform-origin: top left;
	transform: translate(180%, -80%) rotate(90deg);
	padding: 2px;
}

.mytasks-table-grouper-type.mytasks-multi-row
{
	overflow: hidden;
}

.mytasks-table-grouper-component.mytasks-multi-row
{
	overflow: hidden;
}
.ExtraTable{
	/* width: 1800px; */
    /* margin-left: 600px; */
}

/* grouper body */
.mytasks-table-grouper-component.MuiTableCell-root
{
	font-weight: 800;
	font-size: 15px;
	text-align: center;
	width: 6px;
	padding: 5px;
	background-color: #b0bec5;
	border-radius: 3px;
	color: white;
}

.mytasks-table-grouper-type.MuiTableCell-root
{
	font-weight: 800;
	font-size: 15px;
	text-align: center;
	width: 6px;
	padding: 5px;
	
	border-radius: 3px;
	color: white;
}

.mytasks-single-row 
{
	height: 100px;
}

/* grouper top */
.MuiTableCell-root.mytasks-table-grouper-header
{
	padding: 6px;
	width: 6px;
}




.mytasks-table-grouper-component.color-a
{
		background-color: #009688 !important;
}

.mytasks-table-grouper-type.color-a
{
		background-color: #009688 !important;
}
.mytasks-table-grouper-component.color-b
{
	background-color: #9c27b0 !important;
}





.mytasks-table-grouper-type.color-a
{
	background-color: #009688 !important;
}

.mytasks-table-grouper-type.color-b
{
	background-color: #9c27b0 !important;
}

.mytasks-table-grouper-type.color-c
{
	background-color: #ff5722 !important;
}




.mytasks-table-grouper-component.color-c
{
	background-color: #ff5722 !important;
}


.mytasks-table-grouper-component.color-d
{
	background-color: #2196f3 !important;
}






.mytasks-table-grouper-component.color-e
{
		background-color: #673ab7 !important;
}

.custom-chip {
	padding-left: 60px !important; 
	font-size: 2px; 

  }








/* ################################################################################################################ */

