@keyframes animate-in-and-out {
	entry 0% {
		opacity: 0;
		transform: translateY(100%);
	}

	entry 100% {
		opacity: 1;
		transform: translateY(0);
	}

	exit 0% {
		opacity: 1;
		transform: translateY(0);
	}

	exit 100% {
		opacity: 0;
		transform: translateY(-100%);
	}
}

#list-view .slide-animation {
	animation: linear animate-in-and-out;
	animation-timeline: view();
}

.milestone-row-sticky-div {
	position: -webkit-sticky !important;
	position: sticky !important;
	top: 50px;
}

.milestone-table-head th {
	border: 0
}

.milestone-top-bar {
	display: grid;
	grid-template-columns: auto auto;
	margin-bottom: 10px;
}

.milestone-top-bar-left-group {
	text-align: left;
}

.milestone-top-bar-right-group {
	text-align: right;
}

.MuiTableContainer-root.milestone-table-container {
	max-height: calc(100vh - 135px);
}

.MuiStepper-root {
	margin-bottom: 22px;
}

.MuiPaper-root.info-div-paper {
	overflow: hidden;
	padding: 20px;
	background-color: #cfd8dc;
	border-radius: 8px;
}

.MuiTableRow-head.milestone-table-head>.MuiTableCell-root {
	background-color: #263238;
	color: white;
	font-weight: bold;
}

.MuiTableRow-head.tasklist-table-head>.MuiTableCell-root {
	background-color: #263238;
	color: white;
	font-weight: bold;
}

.MuiTableRow-root.info-div-row {
	background-color: #eceff1;
}

/* text */
.milestone-table-grouper-component.milestone-multi-row>div,
.milestone-table-grouper-component.milestone-single-row>div {
	position: relative;
	text-align: center;
	width: 6px;
	transform-origin: top left;
	transform: translate(-75%, 250%) rotate(-90deg);
	padding: 2px;
}

.milestone-table-grouper-type.milestone-multi-row>div,
.milestone-table-grouper-type.milestone-single-row>div {
	position: relative;
	text-align: center;
	width: 6px;
	transform-origin: top left;
	transform: translate(180%, -80%) rotate(90deg);
	padding: 2px;
}

.milestone-table-grouper-type.milestone-multi-row {
	overflow: hidden;
}

.milestone-table-grouper-component.milestone-multi-row {
	overflow: hidden;
}

/* grouper body */
.milestone-table-grouper-component.MuiTableCell-root {
	font-weight: 800;
	font-size: 15px;
	text-align: center;
	width: 6px;
	padding: 5px;
	background-color: rgb(74, 90, 102);
	border-radius: 3px;
	color: white;
	overflow: hidden;
}

.milestone-table-grouper-type.MuiTableCell-root {
	font-weight: 800;
	font-size: 15px;
	text-align: center;
	width: 6px;
	padding: 5px;
	background-color: rgb(74, 90, 102);
	border-radius: 3px;
	color: white;
	overflow: hidden;
}

.milestone-single-row {
	height: 100px;
}

/* grouper top */
.MuiTableCell-root.milestone-table-grouper-header {
	padding: 6px;
	width: 6px;
}

.milestone-table-grouper-component.color-a {
	background-color: #009688 !important;
}

.milestone-table-grouper-component.color-b {
	background-color: #9c27b0 !important;
}

.milestone-table-grouper-component.color-c {
	background-color: #ff5722 !important;
}

.milestone-table-grouper-component.color-d {
	background-color: #2196f3 !important;
}

.milestone-table-grouper-component.color-e {
	background-color: #673ab7 !important;
}

.milestone-table-grouper-type.Release {
	background-color: #388e3c !important;
}

.milestone-table-grouper-type.Research {
	background-color: #880e4f !important;
}

.MuiTableCell-root p {
	margin: 0px;
	display: block;
	margin-top: 5px;
}

.MuiTableCell-root>.MuiBox-root {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.tasklist-table .MuiTableCell-head,
.tasklist-table .MuiTableCell-root {
	padding: 10px;
}

.tasks-table-inside-tasklist {
	height: 960px;
}
/* 
.MuiSkeleton-wave {
	-webkit-animation-fill-mode: initial;  
	animation-fill-mode: initial;
} */