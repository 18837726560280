.MuiTableContainer-root.tickets-table-container
{
	max-height: calc(100vh - 150px);
}
.MuiTableRow-head.tickets-table-head > .MuiTableCell-root
{
	background-color: #202123;
	color: white;
	font-weight: bold;
}

.tickets-table-grouper-header {
  padding: 0px !important;
	width: 0px;
}

.rotated-text {
  position: relative;
  min-width: 3px  !important;
  writing-mode: vertical-rl !important;
  transform: rotate(180deg) !important;
  transform-origin: center;
  padding: 2px !important;
  color: white;
  line-height: 1 !important;
  /* background-color: lightblue; */
}

.MuiTableRow-head.subrow-table-head>.MuiTableCell-root {
	background-color: #263238;
	color: white;
	font-weight: bold;
}

.rotated-text.zvpteam {
  background-color: rgb(120, 71, 18) !important;
}
.rotated-text.Issue 
{
	background-color: #388e3c !important;
}

.rotated-text.Query
{
	background-color: #880e4f !important;
}

.rotated-text.Resolved
{
	background-color: #88160e !important;
}

.rotated-text.Zone
{
background-color: #033847 !important;
}

.rotated-text.AWServer
{
background-color: #bf1a72 !important;
}

.PServer
{
background-color: #b800ac !important;
}

.NIO.Framework
{
background-color: #44057b !important;
}

.rotated-text.PubSub
{
background-color: #7c35af !important;
}

.rotated-text.AMServer
{
background-color: #ba0505 !important;
}

.ZVP.Media
{
background-color: #1e1599 !important;
}

.ZVP.Turn
{
background-color: #1636eb !important;
}

.Intercomm
{
background-color: #693101 !important;
}

.TURN-C
{
background-color: #155f18 !important;
}

.WPNServer
{
background-color: #585858 !important;
}

.DAV
{
background-color: #5b3c88 !important;
}

.RTCPlatform
{
background-color: #078096 !important;
}

.WSServer
{
background-color: #5b258e !important;
}

.MediaRouter-C
{
background-color: #618800 !important;
}

.Common.C
{
background-color: #00b85f !important;
}

.Network-C
{
background-color: #184717 !important;
}

.ZVPInfra
{
background-color: #6b7e0a !important;
}

.MSServer
{
background-color: #a22e00 !important;
}

.MPMServer
{
background-color: #ec5300 !important;
}

.ZVP-QA
{
background-color: #545339 !important;
}

.MEXServer
{
background-color: #af6d49 !important;
}

.ZVPRTMPServer
{
background-color: #877e00 !important;
}

.rotated-text.media_server
/* .AMS-MediaProcessor */
{
background-color: #d2353f !important;
}

.AMS-Processor
{
background-color: #5c0b00 !important;
}

.AMExtConference
{
background-color: #ff483e !important;
}

.AWS-Framework
{
background-color: #721d74 !important;
}

.RTCPServer
{
background-color: #028c8c !important;
}

.ATS-Framework
{
background-color: #9800a9 !important;
}

.Test
{
background-color: #420A05 !important;
}

  .rotated-text:nth-child(odd) {
    background-color: rgb(18, 64, 79);
  }
  
  .rotated-text:nth-child(even) {
    background-color: rgb(229, 238, 229);
  }
  .row-even {
    background-color: #ebebeb;
  }
  
  .row-odd {
    background-color: #f7f7f7;
  }

  .info-div-paper
{
	overflow: hidden;
	padding: 20px;
	background-color: #cfd8dc;
	border-radius: 8px;
}

  .row-background-blue {
    background-color: lightblue;
  }
  
  .row-background-green {
    background-color: lightgreen;
  }
  
  .row-background-yellow {
    background-color: lightyellow;
  }

  .subrowheader
  {
    background-color: #202123;
    color: white;
    font-weight: bold;
  }

  .highlighted-row {
    background-color: #f8f8ff; /* Replace with your desired color */
  }
