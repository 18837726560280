.MuiBreadcrumbs-separator {
    margin: 0px !important;
}

.MuiBreadcrumbs-ol {
    -webkit-box-flex-wrap: nowrap !important;
    --webkit-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
}

.nested-view-overflow-container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    -webkit-mask-image:  linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 0.3%, rgba(0,0,0,1) 85%, rgba(0,0,0,0) 100%);
}
.nested-view-overflow-container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.nested-view-overflow-container-3 {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.nested-view-overflow-container-3::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.nested-view-overflow-container-2 {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  -webkit-mask-image:  linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 0.3%, rgba(0,0,0,1) 99%, rgba(0,0,0,0) 100%);
}
.nested-view-overflow-container-2::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.vertical-view-overflow-container {
    -webkit-mask-image:  linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 4%, rgba(0,0,0,1) 96%, rgba(0,0,0,0) 100%);
}
.vertical-view-overflow-container-2 {
    -webkit-mask-image:  linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 3%, rgba(0,0,0,1) 97%, rgba(0,0,0,0) 100%);
}

.green-check-mark {
    color: green !important;
}

.red-check-mark {
    color: crimson !important;
}
/* 
.bar-fill {
    display: block;
    height: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: rgb(194, 43, 43); 
    background-image: linear-gradient(
      center bottom,
      rgb(194, 43, 43) 37%,
      rgb(84,240,84) 69%
    );
    box-shadow: 
      inset 0 2px 9px  rgba(255,255,255,0.3),
      inset 0 -2px 6px rgba(0,0,0,0.4);
    position: relative;
    overflow: hidden;
  }

  .bar-fill:after {
    content: "";
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background-image: linear-gradient(
      -45deg, 
      rgba(6, 97, 24, 0.8) 25%, 
      transparent 25%, 
      transparent 50%, 
      rgba(6, 97, 24, 0.8) 50%, 
      rgba(6, 97, 24, 0.8) 75%, 
      transparent 75%, 
      transparent
    );
    z-index: 1;
    background-size: 15px 15px;
    animation: move 1s linear infinite;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
  }

  @keyframes move {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 15px 15px;
    }
  } */


  .bar-fill {
    background: repeating-linear-gradient(to right, #5E9E5E 0%, #5E9E5E 20%, #b9de02 50%, #5E9E5E 80%,  #5E9E5E 100%);
    width: 100%;
    background-size: 200% auto;
    background-position: 0 100%;
    animation: gradient 2s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }
  
  @keyframes gradient { 
    0%   { background-position: 0 0; }
    100% { background-position: -200% 0; }
  }