.topbar {
    display: flex;
    flex-direction: row;
    justify-content: right;
    min-height: 30px;
    padding: 0px !important;
}

.flex_row {
    display: flex;
    flex-direction: row;
}

.flex_row_gap {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.flex_row_gap_small {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.hide_scroll_bar {
    overflow-y: scroll;
    -ms-overflow-style: none !important;  /* Internet Explorer 10+ */
    scrollbar-width: none !important;  /* Firefox */
}
.hide_scroll_bar::-webkit-scrollbar { 
    display: none !important;  /* Safari and Chrome */
    width: 0;
    height: 0;
}

/* search-options */
.search_box :global(.MuiInputBase-root) {
    height: 30px;
    width: 250px;
    margin-right: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.button_no_border {
    border-radius: 0px;
}

.button_border {
    transition: all 1s ease;
}
/* search-options-end */

/* filter-options */
.filter_menu_main {
    padding: 0px !important;
}

:global(.MuiList-root.MuiList-padding.MuiMenu-list) {
    padding: 0px !important;
}

.filter_server_menu_main {
    padding: 0px !important;
    max-height: 600px;
}

.filter_menu_item {
    min-width: 120px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 15px 12px 15px 12px !important;
}

.filter_server_menu_item {
    min-width: 200px;
    min-height: 48px !important;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 10px 12px 10px 12px !important;
}
/* filter-options-end */

/* sort-options */
.sort_menu_main {
    padding: 0px !important;
}

.sort_menu_item {
    min-width: 160px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 15px 12px 15px 12px !important;
}
/* sort-options-end */

.top_bar_chips {
    display: flex !important;
    flex-grow: 1 !important;
    margin: 3px 10px 3px 1px !important;
    flex-direction: row !important;
    gap: 10px !important;
    overflow-x: scroll !important;
}

.topbar_right_side {
    display: flex !important;
    flex-direction: row !important;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
}

.overlayText {
    color: white;
    text-align: center;
    font-size: 22px;
}