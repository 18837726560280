.zvp_milestones_history {
    height: 100%;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(12, 1fr);
}

.zvp_year_view_top {
    grid-area: 1 / 1 / 1 / 13;

    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    grid-auto-columns: calc(100% / 3);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none; 

    -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 90%);
    mask-image:  linear-gradient(to right, rgba(0, 0, 0, 0) 10%, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 90%);
}

.zvp_year_view_top > div {
    margin: 3px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding: 0 150px;
}

.zvp_year_view_top > div > div {
    position: sticky;
    left: 48%;
    width: fit-content;
    cursor: default;
    user-select: none;
    transform-origin: center;

    font-size: 1.5rem;
    padding: 5px 20px;
    border-radius: 100px;
    font-weight: bold;
    background-color: #607123;
    background: linear-gradient(to right, #A2D240 0%, #1B8B00 100%);
    color: white;
}

.zvp_history_view {
    grid-area: 2 / 1 / 12 / 13;

    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    grid-auto-columns: calc(100% / 3);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none; 
}

.zvp_history_view > div {
    margin: 3px;

    align-self: center;
    justify-self: center;
}

.zvp_card_hero {
    font-size: 3rem;
    padding: 40px;

    width: 300px;
    height: 500px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    justify-content: center;
    background: rgb(251, 252, 252);
    border-radius: 20px;
    box-shadow: 0 0 1px hsl(0deg 0% 0% / 0.075), 0 0 2px hsl(0deg 0% 0% / 0.075),
        0 0 4px hsl(0deg 0% 0% / 0.075), 0 0 8px hsl(0deg 0% 0% / 0.075),
        0 0 16px hsl(0deg 0% 0% / 0.075);
    transform-origin: 10% 60%;
}

.zvp_card_hero_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.zvp_card_hero_title > .subtitle {
    font-size: 16px;
    color: #1a81c6;
    font-weight: bold;
}

.zvp_card_hero_clusters > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.zvp_card_hero_clusters > div > .dot {
    height: 8px;
    width: 8px;
    background-color: #1a7b88;
    border-radius: 50%;
    display: inline-block;
}

.zvp_card_hero_clusters {
    display: flex;
    flex-direction: column;
    /* width: 60%; */
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
    font-size: 15px;
    font-weight: light;
}

.zvp_card_hero_protocols {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* width: 60%; */
    gap: 5px;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: light;
}

.zvp_card_hero_protocols > div {
    background-color: rgba(86, 147, 0, 0.09);
    color: rgb(0, 0, 0);
    border-radius: 100px;
    padding: 5px 15px;
    margin-bottom: 1px;
}

.zvp_card_hero_protocols > span {
    opacity: 0.25;
    color: #0f79bf;
}


.zvp_card_hero_description {
    display: flex;
    flex-direction: column;
    /* width: 60%; */
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
    color: rgb(37, 130, 49);
}

.zvp_card_hero_notes {
    font-size: 16px;
    color: #bf0f23;
    font-weight: bold;
}

.zvp_year_view_bottom {
    grid-area: 12 / 1 / 13 / 13;

    display: flex;
    align-items: center;
    justify-content: center;
}

.zvp_year_view_bottom > div {
    width: 97%;
    background-color: rgba(111, 141, 121, 0.105);
    padding: 12px 15px ;
    border-radius: 100px;
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
}

.zvp_year_view_bottom > div > div {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    font-size: 14px;
}

.zvp_year_view_bottom > div > div:not(.pads)::before {
    white-space: nowrap;
    font-size: 20px;
    padding-right: 1px;
    content: "· · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · ·";
}

.zvp_year_view_bottom > div > div:not(.pads)::after {
    white-space: nowrap;
    font-size: 20px;
    padding-left: 1px;
    content: "· · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · · ·";
}

.zvp_year_view_slider {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    position: absolute;
    top: 10px;
    left: 13px;
    width: calc(100% - 30px);
    height: 25px;
    background: #d3d3d300;
    accent-color: #d3d3d300;
}

.zvp_year_view_slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 50px;
    height: 30px;
    border-radius: 100px;
    background: #1B8B0066;
    cursor: pointer;
    border: none;
}

.zvp_year_view_slider::-moz-range-thumb {
    width: 50px;
    height: 30px;
    border-radius: 100px;
    background: #1B8B0066;
    cursor: pointer;
    border: none;
}