.Incident-top-bar
{
	margin-bottom: 10px;
	display: grid;
	grid-template-columns: auto auto;
	overflow: hidden;
}

.MuiOutlinedInput-root
{
	height: 35px;
	margin-right: 10px;
}

.MuiCollapse-wrapper
{
	display: flex;
}

.Incident-top-bar-left-group
{
	text-align: left;
}

.Incident-top-bar-right-group
{
	text-align: right;
}

.info-div-paper
{
	overflow: hidden;
	padding: 20px;
	background-color: #cfd8dc;
	border-radius: 8px;
}

.MuiTableContainer-root.Incident-table-container
{
	max-height: calc(100vh - 150px);
}

.MuiTableRow-head.Incident-table-head > .MuiTableCell-root
{
	background-color: #263238;
	color: white;
	font-weight: bold;
}

/* .MuiTableRow-root
{
	background-color: ghostwhite;
} */

.MuiTableCell-root > .MuiBox-root.flex-column > p
{
	margin: 0px;
	display: block;
	margin-top: 5px;
}

.MuiTableCell-root > .MuiBox-root.flex-row > p
{
	margin: 0px;
	display: block;
	margin-left: 5px;
}

.MuiTableCell-root > .MuiBox-root.flex-column
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.MuiTableCell-root > .MuiBox-root.flex-row
{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}


/* ############################################### grouper styles ################################################# */

/* text */
.Incident-table-grouper-component.Incident-multi-row > div, .Incident-table-grouper-component.Incident-single-row > div
{
	position: relative;
	text-align: center;
	width: 6px;
	transform-origin: top left;
	transform: translate(-70%, 121%) rotate(-90deg);
	padding: 2px;
}

.Incident-table-grouper-type.Incident-multi-row > div, .Incident-table-grouper-type.Incident-single-row > div
{
	position: relative;
	text-align: center;
	width: 6px;
	transform-origin: top left;
	transform: translate(180%, -80%) rotate(90deg);
	padding: 2px;
}

.Incident-table-grouper-type.Incident-multi-row
{
	overflow: hidden;
}

.Incident-table-grouper-component.Incident-multi-row
{
	overflow: hidden;
}

/* grouper body */
.Incident-table-grouper-component.MuiTableCell-root
{
	font-weight: 800;
	font-size: 15px;
	text-align: center;
	width: 6px;
	padding: 5px;
	background-color: rgb(74, 90, 102);
	border-radius: 3px;
	color: white;
	overflow: hidden;
}

.Incident-table-grouper-type.MuiTableCell-root
{
	font-weight: 800;
	font-size: 15px;
	text-align: center;
	width: 6px;
	padding: 5px;
	background-color: rgb(74, 90, 102);
	border-radius: 3px;
	color: white;
	overflow: hidden;
}

.Incident-single-row 
{
	height: 100px;
}

/* grouper top */
.MuiTableCell-root.Incident-table-grouper-header
{
	padding: 6px;
	width: 6px;
}

.MuiTableRow-root.info-div-row {
	background-color: #eceff1;
}

.Incident-table-grouper-component.color-a
{
	background-color: #009688 !important;
}

.Incident-table-grouper-component.color-b
{
	background-color: #9c27b0 !important;
}

.Incident-table-grouper-component.color-c
{
	background-color: #ff5722 !important;
}

.Incident-table-grouper-component.color-d
{
	background-color: #2196f3 !important;
}

.Incident-table-grouper-component.color-e
{
	background-color: #673ab7 !important;
}

.Incident-table-grouper-type.Release
{
	background-color: #388e3c !important;
}

.Incident-table-grouper-type.Research
{
	background-color: #880e4f !important;
}

/* ################################################################################################################ */