.MuiTableContainer-root.tickets-table-container
{
	max-height: calc(100vh - 150px);
}
.MuiTableRow-head.tickets-table-head > .MuiTableCell-root
{
	background-color: #202123;
	color: white;
	font-weight: bold;
}

.rotated-text {
    position: relative;
    writing-mode: vertical-rl !important;
    transform: rotate(180deg) !important;
    transform-origin: center;
    padding: 0px !important;
    background-color: lightblue;
  }

  .rotated-text:nth-child(odd) {
    background-color: lightblue;
  }
  
  .rotated-text:nth-child(even) {
    background-color: lightgreen;
  }
  .row-even {
    background-color: #ebebeb;
  }
  
  .row-odd {
    background-color: #f7f7f7;
  }

  .info-div-paper
{
	overflow: hidden;
	padding: 20px;
	background-color: #cfd8dc;
	border-radius: 8px;
}

  .row-background-blue {
    background-color: lightblue;
  }
  
  .row-background-green {
    background-color: lightgreen;
  }
  
  .row-background-yellow {
    background-color: lightyellow;
  }

  .subrowheader
  {
    background-color: #202123;
    color: white;
  }

  .highlighted-row {
    background-color: #f8f8ff; /* Replace with your desired color */
  }