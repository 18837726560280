.outer-side-bar
{
	display: none;
	position: fixed;
	left: 0;
	height: 95vh;
	width: 50px;
	padding: 5px 0px 5px 0px;
	/* background: linear-gradient(16deg, rgba(212,213,232,1) 0%, rgba(232,232,232,1) 100%); */
	background: linear-gradient(300deg, rgb(26, 30, 37) 0%, rgb(36, 40, 53) 100%);
	overflow: hidden;
}

.inner-side-bar
{
	/* font-family: "Zoho_Puvi_Semibold"; */
	font-family: 'Poppins', sans-serif;
	position: fixed;
	left: 0px;
	height: 100vh;
	width: 250px;
	background: rgb(32,33,35);
	background: linear-gradient(16deg, rgba(32,33,35,1) 0%, rgba(44,49,61,1) 100%);
	color: white;
	overflow: hidden;
	/*	padding-top: 15px;*/
	background: linear-gradient(16deg, rgba(32, 33, 35, 1) 0%, rgba(44, 49, 61, 1) 100%);
	border-left: 2px solid rgb(33, 34, 38);
	border-right: 1px solid rgb(33, 34, 38);
}

/* .inner-side-bar:hover {
	background: linear-gradient(16deg, rgba(44, 49, 61, 1) 0%, rgba(32, 33, 35, 1) 100%);
  } */

.outer-tab-icons
{
	height: 35px;
	width: 35px;
	filter: drop-shadow(1px 2px 2px grey);
}

.outer-tab-icons:hover
{
	filter: drop-shadow(1px 2px 2px #2c0845);
}

.outer-tab
{
	cursor: pointer;
	padding: 3px 3px 0px 3px;
	margin-top: 15px;
	margin-bottom: 15px;
}

.outer-tab-selected
{
/*	background-color: #2196F3;*/
	border-radius: 3px 25px 25px 3px;
/*	filter: drop-shadow(1px 1px 2px #219FFF);*/
	margin-right: 3px;
	padding: 3px;
}

.inner-tab
{
	cursor: pointer;
	padding: 10px 10px 10px 15px;
}

/*.inner-tab:not(.inner-tab-selected):hover*/
/*{*/
	/*color: white !important;
	filter: drop-shadow(1px 1px 2px #219FFF);*/
/*}*/

.inner-tab-selected
{
	background-color: #2196F3;
	color: black;
	border-radius: 5px;
/*	filter: drop-shadow(1px 1px 2px #219FFF);*/
}

.link-component
{
	color: inherit;
	text-decoration: inherit;
}

  